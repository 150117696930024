<template>
    <div>
        <h5 class="f-w-600">
            Preview
        </h5>
        <div v-for="(section, index) in formConfig" :key="section.id" class="box">
            <h5 class="kyb__title mb-3"><span :class="titleIcons[section.step]"></span> {{ $t(section.title) }}</h5>
            <div class="row">
                <template v-for="(field, fieldName) in section.form">
                    <div v-if="data[fieldName]" :key="fieldName" class="col-md-6">
                        <h6 class="text-muted">
                            {{ $t(field.label) }}
                        </h6>
                        <p>
                            <template v-if="field.el === 'input' && field.type === 'file'">
                                <template>
                                    <embed
                                        :src="getFileUrl(data[fieldName])"
                                        type="application/pdf"
                                        frameBorder="0"
                                        scrolling="none"
                                        height="200px"
                                        width="100%"
                                    />
                                </template>
                            </template>
                            <template v-else-if="field.el === 'input'">
                                {{ data[fieldName] }}
                            </template>
                            <template v-else-if="field.el === 'select'">
                                {{ data[fieldName][field.option_text] }}
                            </template>
                        </p>
                    </div>
                </template>
            </div>
            <hr v-if="index !== formConfig.length -1" class="kyc__separator" />
        </div>
    </div>
</template>

<script type='text/javascript'>
export default {
    name: 'Preview',
    props: {
        formConfig: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            saving: false,
            titleIcons: {
                1: 'far fa-building',
                2: 'far fa-user',
                3: 'far fa-file',
            },
        };
    },
    methods: {
        changeInputFile(event, fieldName) {
            const input_value = event.target.files[0];

            if (input_value) {
                this.data[fieldName] = input_value;
            }
        },
        getFileUrl(file) {
            if (file.source) {
                return file.source;
            }

            return URL.createObjectURL(file);
        },
    },
};
</script>
