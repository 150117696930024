<template>
    <div class="kyb card-body d-flex flex-column justify-content-between">
        <template v-if="!serviceLoading">
            <div class="text-center">
                <img src="@/assets/images/tendencys.svg" class="img-80 mb-4" alt="Tendencys" />
                <h5 class="f-w-600">
                    {{ $t('Company Verification') }}
                </h5>
            </div>
            <v-observer v-if="!showSummary" ref="formKyb" tag="form" class="" @submit.prevent="handleSubmit()">
                <h5 class="f-w-600">
                    {{ $t('You are about to verify your company!') }}
                </h5>
                <b-alert v-if="review" fade variant="danger" show class="kyb__alert-danger">
                    <p class="mb-1">
                        <strong>{{ $t('Verification failed') }}</strong>
                    </p>
                    <p class="mb-2">
                        {{ $t('We are sorry that your identity has not been approved, it is necessary for you to provide some information again, the review comments are attached below:') }}
                    </p>
                    <ul>
                        <li>
                            {{ review.comments.general }}
                        </li>
                    </ul>
                </b-alert>
                <b-alert v-else fade show class="kyb__alert-info">
                    <ul>
                        <li>
                            {{ $t('Complete this short form to activate your account.') }}
                        </li>
                        <li>
                            {{ $t('After completing it for the security of your company, it is necessary to verify the legal representative of your company.') }}
                        </li>
                        <li>
                            {{ $t('Once the process is complete, we will be validating your information and you will receive an email with the status of your account.') }}
                        </li>
                    </ul>
                </b-alert>
                <div v-for="(section, index) in formConfig" :key="section.id" class="box">
                    <h5 class="kyb__title mb-3"><span :class="titleIcons[section.step]"></span> {{ $t(section.title) }}</h5>
                    <b-alert v-if="section.step === 3" fade show class="kyb__alert-info">
                        <p class="mb-0">
                            {{ $t('You need to upload the following documents marked as mandatory') }} (*)
                        </p>
                    </b-alert>
                    <div class="row">
                        <div v-for="(field, fieldName) in section.form" :key="fieldName" class="col-md-6">
                            <v-validation 
                                v-slot="{ validated, passed, errors }"
                                :rules="showFieldValidations(fieldName) ? field.rules : null"
                                :name="fieldName"
                            >
                                <b-form-group :label-for="fieldName">
                                    <template #label>
                                        {{ $t(field.label) }}
                                        <span :class="{ 'kyb__label--required': field.required, 'kyb__label--optional': !field.required }">
                                            {{ `${field.required ? '*' : '(opcional)'}` }}
                                        </span>
                                    </template>
                                    <b-input-group>
                                        <template v-if="field.el === 'input' && field.type === 'file'">
                                            <b-form-file
                                                v-if="showFieldValidations(fieldName)"
                                                :id="fieldName"
                                                :type="field.type"
                                                :state="(validated && showFieldValidations(fieldName)) ? passed : null"
                                                :placeholder="user[fieldName] ? user[fieldName].name : $t(field.label)"
                                                :value="user[fieldName]"
                                                @change="changeInputFile($event, fieldName)"
                                            />
                                            <template v-else >
                                                <b-form-file
                                                    :id="fieldName"
                                                    :type="field.type"
                                                    :placeholder="$t(field.label)"
                                                    disabled
                                                />
                                            </template>
                                        </template>
                                        <b-form-input
                                            v-else-if="field.el === 'input'"
                                            v-model="user[fieldName]"
                                            :id="fieldName"
                                            :type="field.type"
                                            :state="(validated && showFieldValidations(fieldName)) ? passed : null"
                                            :placeholder="$t(field.label)"
                                            :disabled="!showFieldValidations(fieldName)"
                                            @change="changeField(fieldName)"
                                        />
                                        <select-option
                                            v-else-if="field.el === 'select'" class="w-100"
                                            v-bind="field"
                                            v-model="user[fieldName]"
                                            :id="fieldName"
                                            :name="fieldName"
                                            :source="field.source"
                                            :source-data="field.source_data"
                                            :state="(validated && showFieldValidations(fieldName)) ? passed : null"
                                            :disabled="!showFieldValidations(fieldName)"
                                            @change="changeField(fieldName)"
                                            @load-options="setOptionsInField"
                                        />
                                    </b-input-group>
                                    <b-form-invalid-feedback :state="(validated) ? passed : null">
                                        {{errors[0]}}
                                    </b-form-invalid-feedback>
                                    <template v-if="field.el === 'input' && field.type === 'file'">
                                        <div v-if="review && user[fieldName] && user[fieldName].source" class="my-2">
                                            <p class="text-muted">
                                                {{ $t('Previously uploaded file') }}:
                                                <br />
                                                <span @click="openFile(user[fieldName].source)" class="kyb__link text-success" target="_blank" rel="noopener noreferrer">
                                                    <span class="fas" :class="user[fieldName].pdf ? 'fas fa-file-image' : 'fa-file-pdf'"></span>
                                                    {{ user[fieldName].name }}
                                                </span>
                                            </p>
                                        </div>
                                        <b-alert v-if="showFieldComments(fieldName)" class="mt-3 d-flex kyb__alert-danger" variant="danger" fade show>
                                            <span class="far fa-comment-alt mt-1 mr-2"></span>
                                            <p class="mb-0">
                                                <strong>{{ $t('Comments') }}</strong>
                                                <br />
                                                {{ review.comments[fieldName] }}
                                            </p>
                                        </b-alert>
                                    </template>
                                </b-form-group>
                            </v-validation>
                        </div>
                    </div>
                    <hr v-if="index !== formConfig.length -1" class="kyc__separator" />
                </div>
                <div class="row mb-2">
                    <div class="offset-md-8 col-md-4">
                        <b-button type="submit" variant="primary" class="w-100" :disabled="saving">
                            <div v-if="saving">
                                <em class="fa fa-spinner fa-spin"></em>
                            </div>
                            <div v-else>
                                {{ $t('Continue') }}
                            </div>
                        </b-button>
                    </div>
                </div>
            </v-observer>
            <template v-else>
                <preview :form-config="formConfig" :data="user" />
                <div class="row mb-2">
                    <div class="offset-md-8 col-md-4 d-flex justify-content-end flex-wrap">
                        <b-button variant="outline-secondary" class="kyb__preview-button mr-0 mr-md-2 mb-2 mb-md-0" @click="showSummary = false">
                            <div>
                                {{ $t('Back') }}
                            </div>
                        </b-button>
                        <b-button variant="primary" class="kyb__preview-button" @click="$refs.confirmationModal.show()">
                            <div>
                                {{ $t('Save') }}
                            </div>
                        </b-button>
                    </div>
                </div>
                <b-modal ref="confirmationModal" :title="$t('Confirm sending information')" centered size="md" footer-class="justify-content-end">
                    <p>
                        {{ $t('Thank you for completing your information. It is important that you verify that all the information is correct in order to expedite the approval of your account.') }}
                    </p>
                    <p class="mb-0">
                        {{ $t('Once you click on Continue, the individual verification that must be carried out by the legal representative of the company will begin.') }}
                    </p>
                    <template v-slot:modal-footer>
                        <button class="btn btn-outline-secondary mr-2" :disabled="saving" @click="$refs.confirmationModal.hide()">
                            {{ $t('Close') }}
                        </button>
                        <button class="btn btn-success" :disabled="saving" @click="saveKybData">
                            <em v-if="saving" class="fa fa-spinner fa-spin"></em>
                            <span>
                                {{ $t('Continue') }}
                            </span>
                        </button>
                    </template>
                </b-modal>
            </template>
        </template>
    </div>          
</template>

<script type='text/javascript'>
import SelectOption from '@/components/fields/SelectOption.vue';
import FormMixin from '@/plugins/mixin/form.mixin';
import Preview from './preview.vue';

export default {
    name: 'Kyb',
    components: { SelectOption, Preview },
    mixins: [FormMixin],
    props: {
        id: {
            type: String,
            default: null,
        },
        serviceLoading: {
            type: Boolean,
            default: false,
        },
        formName: {
            type: String,
            default: null,
        },
        country: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            formConfig: [],
            user: {},
            review: null,
            saving: false,
            showSummary: false,
            suggestedInputsMap: {
                nationality: 'state',
            },
            inputsMap: {
                state: [
                    {
                        source: 'nationality',
                        paramName: 'country',
                    }
                ],
            },
            titleIcons: {
                1: 'far fa-building',
                2: 'far fa-user',
                3: 'far fa-file',
            },
        };
    },
    async mounted() {
        await this.getApplicationStatus();
        await this.loadForm();
    },
    methods: {
        setOptionsInField({ options, attrs }) {
            this.setValueField(attrs.name, { selectOptions: options });
        },
        changeField(field) {
            if (field in this.suggestedInputsMap) {
                this.changeOptionsBySuggestion(this.suggestedInputsMap[field]);
            }

            if (field === 'postal_code') {
                this.changePostalCode();
            }
        },
        changeOptionsBySuggestion(inputName, defaultValue = null) {
            const field = this.getFormConfigField(inputName);

            if (field && field.el === 'select') {
                let source = [];
                const paramList = this.inputsMap[inputName];
                const formattedParams = {};

                field.urlSource = field.urlSource || field.source;
                this.user = { ...this.user, [inputName]: defaultValue };
                
                paramList.forEach(param => {
                    const sourceConfig = this.getFormConfigField(param.source);
                    const { [param.source]: { [sourceConfig.option_value]: paramValue } } = this.user;

                    formattedParams[param.paramName] = paramValue;
                });

                source = this.mustache(field.urlSource, formattedParams);
                
                this.setValueField(inputName, { source, disabled: false });
            }
        },
        showFieldValidations(fieldName) {
            if (!this.review) {
                return true;
            }

            return this.review?.comments?.[fieldName];
        },
        showFieldComments(fieldName) {
            return this.review?.comments?.[fieldName];
        },
        handleError(error) {
            const message = error?.response?.data?.message || this.$t('Something went wrong, try later');
            this.$toasted.global.errorMessage(message);
        },
        openFile(file) {
            window.open(file, '_blank');
        },
        changeInputFile(event, fieldName) {
            const input_value = event.target.files[0];

            if (input_value) {
                this.user[fieldName] = input_value;
            }
        },
        async changePostalCode() {
            const { nationality, postal_code } = this.user;

            if (!nationality || !postal_code) return;

            try {
                const suggestion = await this.axiosAccount.get(`/api/catalogs/zipcode/${nationality.code}/${postal_code}`)
                    .then(response => (response?.data?.length ? response.data[0] : null));
                
                if (suggestion) {
                    const { state, locality } = suggestion;
                    
                    state.code = state.code['2digit'] || state.code['1digit'] || state.code['3digit'] || '';
                    
                    this.user = { ...this.user, city: locality, state };
                }
            } catch (error) {
                const message = error?.response?.data?.message || this.$t('Something went wrong, try later');
                this.$toasted.global.errorMessage(message);
            }
        },
        async loadForm() {
            try {
                const params = { name: this.formName, country: this.country };
                const { data } = await this.axiosAccount.get('/api/forms', { params });

                this.formConfig = data;

                await this.getDefaultCountry();

                this.$emit('update:service-loading', false);
            } catch(error) {
                this.handleError(error);
            }
        },
        async handleSubmit() {
            if (await this.$refs.formKyb.validate()) {
                this.showSummary = true;
            }
        },
        async getFormData() {
            const userData = { ...this.user };
            const fileNames = [];

            this.formConfig.forEach(section => {
                Object.entries(section.form).forEach(([fieldName, field]) => {
                    if (field.el === 'input' && field.type === 'file' && this.showFieldValidations(fieldName)) {
                        fileNames.push(fieldName);
                    }
                });
            });

            for (let fileName of fileNames) {
                if (userData[fileName]) {
                    const encodedFile = await this.convertFileToBase64(userData[fileName]);
                    const name = userData[fileName].name;
    
                    userData[fileName] = {
                        source: encodedFile,
                        mimetype: name.split('.').pop(),
                        name: name,
                    };
                }
            }
            
            return { kyb_information: userData };
        },
        async saveKybData() {
            try {
                this.saving = true;
                
                const formData = await this.getFormData();
                await this.axiosAccount.put(`/api/kyb/${this.id}`, formData, { timeout: 25000 });

                this.$emit('kyb-created', {
                    type: 'success',
                    message: 'formSentSuccessfully',
                });
            } catch(error) {
                this.handleError(error);
            } finally {
                this.saving = false;
            }
        },
        async getApplicationStatus() {
            try {
                const { data } = await this.axiosAccount.get(`/api/kyb/${this.id}`, { params: { local: true } });
                const { kyb_result } = data;

                if (!kyb_result) return;

                const { info, review } = kyb_result;
                const { result } = review;

                if (result.status === 'init') {
                    this.$emit('kyb-created', {
                        type: 'success',
                        message: 'pending',
                    });
                    return;
                }

                if (result.status === 'complete' && result.reject_type === 'final' && result.reject_labels.length > 0) {
                    this.$emit('kyb-created', {
                        type: 'error',
                        message: 'declined',
                    });
                    return;
                }

                if (result.status === 'complete' && result.reject_type === 'final' && result.reject_labels.length < 1) {
                    this.$emit('kyb-created', {
                        type: 'success',
                        message: 'completed',
                    });
                    return;
                }

                if (result.status === 'process' && result.reject_type === 'retry') {
                    result.reject_labels.forEach(label => {
                        info[label] = null;
                    });

                    this.review = result;
                    this.user = info;
                }

                if (result.status === 'complete' && result.reject_type === 'reject') {
                    this.$emit('kyb-created', {
                        type: 'error',
                        message: 'declined',
                    });
                    return;
                }
            } catch (error) {
                this.handleError(error);
            }
        },
        async getDefaultCountry() {
            if (this.review) return;

            try {
                const { data } = await this.axiosAccount.get('/api/catalogs/countries');

                this.user.nationality = data.find(country => country.code === this.country);
                this.changeField('nationality');
            } catch(error) {
                this.handleError(error);
            } finally {
                this.saving = false;
            }
        }
    },
};
</script>

<style lang="scss">
.kyb {
    &__title {
        margin-bottom: 20px;
        font-weight: 600;
        color: #000000;
        font-size: 1rem;
    }

    &__separator {
        border-top: 1px solid rgba(148, 148, 148, 0.1);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__link {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__alert-info {
        border: 0 !important;
        border-left: 5px solid #98c2f2 !important;
        background-color: #d7eaf8 !important;
        color: #5f95da !important;
    }

    &__alert-danger {
        border: 0 !important;
        border-left: 5px solid #82141e !important;
    }

    &__preview-button {
        width: calc(50% - 0.25rem);

        @media(max-width: 768px) {
            width: 100%;
        }
    }

    &__label {
        &--required {
            color: #dc3545;
        }

        &--optional {
            color: #c1c1c1;
            font-size: 0.9rem;
        }
    }
}
</style>

